<template>
  <b-card>
    <confirm-modal ref="confirmModal" />
    <create-container-types-modal ref="createContainerTypesModal">
    </create-container-types-modal>

    <div class="d-flex justify-content-center flex-column">
      <b-row class="topbar mb-1">
        <b-col cols="3">
          <input
            type="text"
            class="form-control"
            :placeholder="T('Web.Generic.Search', 'Search')"
            v-model="search"
          />
        </b-col>
        <b-col>
          <b-button @click="openCreateContainerTypesModal" variant="primary">
            {{ T("Web.Generic.CreateNew", "Create new") }}
          </b-button>
        </b-col>

        <loader class="loader" ref="loaderContainerType"></loader>
      </b-row>
      <b-row>
        <b-table
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          :fields="tableHeadersFields"
          :items="filterted"
        >
          <template #cell(name)="data">
            <input
              @change="updateContainerType(data.item)"
              type="text"
              class="form-control"
              v-model="data.item.name"
            />
          </template>

          <template #cell()="data">
            <b-button
              variant="outline-danger"
              class="mr-2"
              @click="removeContainerType(data.item)"
            >
              {{ T("Web.Generic.Archive", "Archive") }}
            </b-button>
          </template>
        </b-table>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import CreateContainerTypesModal from "@/components/modals/infoCards/CreateContainerTypesModal.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import loader from "@/components/layout/loader.vue";

import { BCard, BButton, BRow, BCol, BTable } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BRow,
    BTable,
    BCol,
    CreateContainerTypesModal,
    loader,
    ConfirmModal,
  },
  async created() {
    await this.fetchContainerTypes();
  },
  data() {
    return {
      search: null,
    };
  },
  computed: {
    ...mapGetters({ containerTypes: "containerTypes/list" }),
    filterted() {
      if (this.search == null || this.search == "") return this.containerTypes;

      let filtered = this.containerTypes.filter((e) => {
        return e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });

      return filtered;
    },
    tableHeadersFields() {
      return [
        {
          key: "name",
          label: this.T("Web.Generic.Name"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchContainerTypes: "containerTypes/fetchList",
      putContainerTypes: "containerTypes/put",
      deleteContainerTypes: "containerTypes/delete",
    }),

    async openCreateContainerTypesModal() {
      const result = await this.$refs.createContainerTypesModal.open("");
      if (result != undefined && result.id.length > 0)
        this.$store.dispatch(`containerTypes/fetchList`);
    },
    async removeContainerType(containerType) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T(
            "Web.Generic.ArchiveConfirm",
            "Confirm that you are about to archive this item"
          )
        ))
      )
        return;
      this.$refs.loaderContainerType.loading();
      await this.deleteContainerTypes({ resourceId: containerType.id });
      this.$store.dispatch(`containerTypes/fetchList`);
      this.$refs.loaderContainerType.loadCompelete();
    },
    async updateContainerType(containerType) {
      this.$refs.loaderContainerType.loading();
      await this.putContainerTypes({ data: containerType });
      this.$refs.loaderContainerType.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>
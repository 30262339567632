<template>
  <b-card no-body>
    <div class="p-2">
      <b-button
        variant="secondary"
        size="sm"
        class="float-right"
        @click="addPriceLine"
      >
        Tilføj linje
        <feather-icon icon="PlusIcon" />
      </b-button>
    </div>
    <b-table
      :items="prices"
      :fields="tableFields"
      show-empty
      :empty-text="T('Web.Generic.TableEmpty')"
      small
    >
      <template #cell(supplierContactId)="data">
        <b-form-select
          :options="supplierContacts"
          text-field="fullName"
          value-field="id"
          size="sm"
          v-model="data.item.supplierContactId"
          @change="updatePriceItem(data.item)"
        />
      </template>
      <template #cell(sourceId)="data">
        <b-form-select
          :options="sourceDestinationOptions"
          text-field="name"
          value-field="id"
          size="sm"
          v-model="data.item.sourceId"
          @change="updatePriceItem(data.item)"
        />
      </template>
      <template #cell(destinationId)="data">
        <b-form-select
          :options="sourceDestinationOptions"
          text-field="name"
          value-field="id"
          size="sm"
          v-model="data.item.destinationId"
          @change="updatePriceItem(data.item)"
        />
      </template>
      <template #cell(soloPrice)="data">
        <b-form-input
          size="sm"
          type="number"
          v-model="data.item.soloPrice"
          @change="updatePriceItem(data.item)"
        />
      </template>
      <template #cell(doublePirce)="data">
        <b-form-input
          size="sm"
          type="number"
          v-model="data.item.doublePirce"
          @change="updatePriceItem(data.item)"
        />
      </template>
      <template #cell(partOfDoublePirce)="data">
        <b-form-input
          size="sm"
          type="number"
          v-model="data.item.partOfDoublePirce"
          @change="updatePriceItem(data.item)"
        />
      </template>
      <template #cell(action)="data">
        <b-button variant="danger" size="sm" @click="deletePriceItem(data.item)"
          >Fjern <feather-icon icon="TrashIcon"
        /></b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BButton, BTable, BFormSelect, BFormInput } from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { uuid } from "vue-uuid";
export default {
  components: {
    BCard,
    BTable,
    BFormSelect,
    BFormInput,
    BButton,
    FeatherIcon,
  },
  data() {
    return {
      prices: [],
      supplierContacts: [],
      locations: [],
      recivingFacilities: [],
    };
  },
  computed: {
    tableFields() {
      return [
        {
          key: "supplierContactId",
          label: "Leverandør",
          sortable: true,
        },
        {
          key: "sourceId",
          label: "Fra",
          sortable: true,
        },
        {
          key: "destinationId",
          label: "Til",
          sortable: true,
        },
        {
          key: "soloPrice",
          label: "Pris solo-kørsel",
          sortable: true,
        },
        {
          key: "doublePirce",
          label: "pris dobbelt-kørsel",
          sortable: true,
        },
        {
          key: "partOfDoublePirce",
          label: "Pris delvis dobbelt-kørsel",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ];
    },
    sourceDestinationOptions() {
      return [
        {
          name: "Vælg lokation eller modtageranlæg",
          id: "00000000-0000-0000-0000-000000000000",
          disabled: true,
        },
        {
          label: "Lokation",
          options: this.locations,
        },
        {
          label: "Modtageranlæg",
          options: this.recivingFacilities,
        },
      ];
    },
  },
  methods: {
    async addPriceLine() {
      let item = {
        id: uuid.v4(),
        sourceId: "00000000-0000-0000-0000-000000000000",
        destinationId: "00000000-0000-0000-0000-000000000000",
        soloPrice: 0,
        doublePirce: 0,
        partOfDoublePirce: 0,
        supplierContactId: "00000000-0000-0000-0000-000000000000",
      };
      const pricesService = new ResourceService(
        "background-data/transport-prices"
      );
      await pricesService.post("", item);
      this.prices.unshift(item);
    },
    async updatePriceItem(item) {
      const pricesService = new ResourceService(
        "background-data/transport-prices"
      );
      await pricesService.post(item.id, item);
    },
    async deletePriceItem(item) {
      const pricesService = new ResourceService(
        "background-data/transport-prices"
      );
      await pricesService.delete(item.id);
      this.prices.splice(this.prices.indexOf(item), 1);
    },
  },

  async created() {
    const supplierService = new ResourceService("supplier-contacts");
    this.supplierContacts = (await supplierService.get("")).data.data;
    this.supplierContacts.unshift({
      fullName: "Vælg leverandør",
      id: "00000000-0000-0000-0000-000000000000",
      disabled: true,
    });
    const locationService = new ResourceService("locations");
    this.locations = (await locationService.get("")).data.data;
    const recivingFacilitiesService = new ResourceService(
      "receiving-facilities"
    );
    this.recivingFacilities = (
      await recivingFacilitiesService.get("")
    ).data.data;
    const pricesService = new ResourceService(
      "background-data/transport-prices"
    );
    this.prices = (await pricesService.get("")).data.data;
  },
};
</script>